import { Expose } from "class-transformer";
import { QuestionAnswerType, QuestionDropdownType } from "@altertec_gparn/lib";

export class UpdateQuestionDto {
  @Expose()
  id: string;

  @Expose()
  title: string;

  @Expose()
  subtitle?: string;

  @Expose()
  answerType: QuestionAnswerType;

  @Expose()
  checklistId?: string;

  @Expose()
  dropdownType?: QuestionDropdownType;

  @Expose()
  generatesIncident?: boolean;

  @Expose()
  equipmentCategoryId?: string;

  @Expose()
  documentationTypeId?: string;

  @Expose()
  order?: number;
}
